<br><h2>Contact</h2>
<br /><br />
<p>
  <a
    href="mailto:pentingaproductions@gmail.com"
    class="fa fa-fw fa-envelope"
    title="Email"
  ></a
  >&nbsp;&nbsp;<a href="mailto:pentingaproductions@gmail.com">Email</a>
</p>
<p>
  <a
    href="https://www.facebook.com/Pentinga-Productions-100710045744255"
    target="_blank"
    class="fa fa-facebook"
    title="Facebook"
  ></a
  >&nbsp;&nbsp;<a
    href="https://www.facebook.com/Pentinga-Productions-100710045744255"
    target="_blank"
    >Find us on Facebook</a
  >
</p>
<p>
  <a
    href="https://www.instagram.com/pentingaproductions/"
    target="_blank"
    class="fa fa-instagram"
    title="Instagram"
  ></a
  >&nbsp;&nbsp;<a
    href="https://www.instagram.com/pentingaproductions/"
    target="_blank"
    >Find us on Instagram</a
  >
</p>
<p>
  <a
    href="https://www.youtube.com/channel/UCcwK8fXirDnUa9P3SveshBg"
    target="_blank"
    class="fa fa-youtube"
    title="Youtube"
  ></a
  >&nbsp;&nbsp;<a
    href="https://www.youtube.com/channel/UCcwK8fXirDnUa9P3SveshBg"
    target="_blank"
    >Find us on Youtube</a
  >
</p>
<p>
  <a
    href="https://www.patreon.com/pentingaproductions"
    target="_blank"
    class="fa fa-patreon"
    title="Patreon"
  ></a
  >&nbsp;&nbsp;<a
    href="https://www.patreon.com/pentingaproductions"
    target="_blank"
    title="Patreon"
    img
    src=""
    target="_blank"
    >Become a Patron</a
  >
</p>
