<br />
<h2>Become a real-life patron of the arts!</h2>
<p>
  Whether wooden puzzles is your thing or not, you can become a patron of the
  art of puzzling! <br /><br />You can support this unique business AND learn
  all about the art of wooden puzzle making!
</p>
<p>
  Patreon is the platform and way to join in the fun by paying Pentinga
  Productions for the stuff you are curious about or love!
</p>
<br />
<p>
  You can pay a few dollars a month to support a unique and engaging enterprise!
  And you can support your favourite creator making a living doing what they
  love to do and do best…all thanks to you!
</p>
<p class="color-background-box">
  <b>It's so easy to become a patron and support Pentinga Productions!</b>
</p>
<p>
  We show our gratitude for becoming a patron in many creative and exciting
  ways:
</p>

<ul>
  <li>
    Learn the art of puzzle making through enlightening and informative videos!
  </li>
  <li>Get first dibs on viewing exclusive videos!</li>
  <li>Come on a birding photography expedition, virtually!</li>
  <li>
    Get your name associated with Pentinga Productions as our way of saying
    thank you!
  </li>
</ul>
<p>
  <b
    >The way support the art of wooden puzzle making is to go to
    <a href="https://www.patreon.com/pentingaproductions" target="_blank"
      >Patreon</a
    >
    to learn all about it!</b
  >
</p>
<div class="patreon-button">
  <a
    class="button"
    href="https://www.patreon.com/pentingaproductions"
    target="_blank"
    >Patreon</a
  >
</div>
<br />
<br />
