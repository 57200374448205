<br />
<h2>Testimonials</h2>
<br />
<p>
  When a client told me she referred a friend to Pentinga Productions, I thanked
  her and expressed how valuable word-of-mouth is to my business. She replied
  saying:
</p>
<p class="emphasize-text">
  <b>
    <i>
      I am a strong believer in supporting small businesses, especially when
      they offer something unique!!!
    </i>
  </b>
</p>
<hr />
<p>A client eagerly awaiting her puzzle order of two puzzles:</p>
<p class="emphasize-text">
  <i>
    <b> I'm so excited to see them! Such a unique gift! </b>
  </i>
</p>
<b><hr /></b>
<p>
  Exclamation from a client who ordered a Pentinga Productions puzzle for her
  mom for Christmas:
</p>
<p class="emphasize-text">
  <i>
    <b
      >The reviews are in and overwhelmingly positive!
      <p></p>
      My mom, sister, and I just finished building your puzzle. It's so
      beautiful and the quality is amazing! We all love it!
    </b>
  </i>
</p>
<br />
<div class="center">
  <img
    class="testimonial"
    src="../../../assets/images/Photo for Website Testimonials Page (Small).jpg"
  />
</div>
<br />
<br />
<br />
