import { Component } from '@angular/core';

@Component({
  selector: 'patronage',
  templateUrl: './patronage.component.html',
  styleUrls: ['./patronage.component.css']
})

export class PatronageComponent {
}
