<br />
<h2>About Pentinga Productions</h2>
<img class="small_about_pic" src="../../../assets/images/ruth_sawdust.jpg" title="Ruth creating puzzles"/>
<br />
<p>My name is Ruth Pentinga.</p>
<p>
  I am the person covered in sawdust at the scroll saw
  <span class="emphasize-text">creating puzzles for you!</span>
</p>
<p> I am also an artist and photographer who loves nature, birds, hiking, creative
  pursuits, challenges and puzzling!
</p>
<hr />
<h3 class="emphasize-text">The HISTORY and REASON for Pentinga Productions</h3>
<img class="medium_about_pic" src="../../../assets/images/Chickadee on my hat.jpg" title="Ruth with chickadee!"/>
<p>It all started when I was a child...</p>
<p>
  My step-grandfather used to make wooden puzzles out of old calendar photos
  glued onto wood and individually cut with his power saw.
</p>
<p>
  He'd give his puzzles to us as gifts and each time I got one I couldn't wait
  to get started!
</p>
<p>
  He would often give us the puzzles in pieces, so we never had a picture of
  what the puzzle was. We just had the uniquely-shaped pieces and hints of
  colour to try and figure out how the thing went together. I loved that
  challenge!
</p>
<p>
  His puzzles were entirely unique, durable, creative and challenging in ways
  that store-bought mass-produced puzzles never were.
</p>
<p>Many of us in our family do his puzzles again and again every year!</p>
<p>
  Over the years I'd always marvelled at his puzzles and hoped to one day make
  them myself!
</p>
<p>I credit him as my inspiration!</p>
<br />
<h4 class="emphasize-text">Fast forward to 2020...</h4>
<p>
  I bought a scroll saw, determined to learn, on my own, how to make amazing
  puzzles like my step-grandfather used to make.
</p>
<p>
  This became a lovely distraction from the isolation the pandemic brought to
  our lives.
</p>
<p>
  I gave my first few puzzles as gifts. I started using my own photos of nature
  and birds and photos of my loved ones.
</p>
<p>
  Then I began to wonder if other people would enjoy these awesome puzzles as
  much as we all do!
</p>
<br />
<h4 class="emphasize-text">Fast forward to today...</h4>
<p>
  I was commissioned to make a bunch of puzzles for a high-end gift shop using
  my own professional photos.
</p>
<p>Then I got to thinking...</p>
<ul>
  <li>People might like to have their own beloved photos made into puzzles</li>
  <li>
    They'd like puzzles of photos of their family, friends, pet, children,
    grandchildren, home/cottage, travels, special events, garden...
  </li>
  <li>Folks could engage with their own photos in a new way!</li>
  <li>
    Children would love to do puzzles of themselves, their family, friends,
    artwork…
  </li>
</ul>
<h4 class="emphasize-text">So I started Pentinga Productions!</h4>
<hr />
<h3 class="emphasize-text">It's all about Creativity...</h3>
<p>
  As I sit down to cut each puzzle piece individually with my scroll saw, I
  allow the creative process take over resulting in puzzles that bring out their
  own unique charm!
</p>
<p class="emphasize-text">
  <b>There is nothing like these puzzles anywhere!</b>
</p>
<hr />
<div class="color-border-box">
  <img class="small_pic" src="../../../assets/images/gallery/Puzzling Animation 1.gif" title="puzzlers puzzling" />
  <h3 class="emphasize-text">Puzzling With Others:</h3>
  <ul>
    <li>
      Conversations over puzzles with others are SO special — they feel,
      somehow, safer or easier!
    </li>
    <li>
      As you chat you have good laughs, share stories, catch up on news, talk
      and talk… in a <i>different</i> way than face-to-face conversations
    </li>
    <li>
      When silence occurs, you can feel the magic of the moment — friends
      working companionably towards a positive common goal
    </li>
    <li>The experience actually takes on a life of its own!</li>
    <li>Support each other and <i>cheer</i> at finding a piece</li>
    <li>Create a puzzling club that meets monthly!</li>
    <li>Have dissectology* challenges</li>
    <li>
      Let me know how this experience was for you — I love to hear about puzzle
      escapades!
    </li>
    <li>
      Leave any drinks and snacks on a side table so as not to spill on the
      beloved puzzle!
    </li>
    <li>Fun!!!</li>
  </ul>
</div>
<br />
<div class="color-border-box">
  <img class="small_pic" src="../../../assets/images/gallery/A puzzler puzzling (Small).jpg" title="a puzzler puzzling"/>
  <h3 class="emphasize-text">Puzzling by Yourself:</h3>
  <ul>
    <li>Enjoyment, nostalgia, pleasure...</li>
    <li>
      Maybe try listening to your favourite music while assembling your unique
      puzzle
    </li>
    <li>Enjoy the unfolding of the photo as each unique piece is placed</li>
    <li>
      Keep a journal of your thoughts as you puzzle — your thoughts may wander
      all over the place!
    </li>
    <li>
      Let me know how this experience was for you — I love to hear about puzzle
      escapades!
    </li>
    <li>
      Leave any drinks and snacks on the side table so as not to spill on the
      beloved puzzle!
    </li>
    <li>Fun!!!</li>
  </ul>
</div>
<h3 class="emphasize-text">Acknowledgements</h3>
<p>I wish to recognize and especially thank:</p>
<ul class="check">
  <li>
    My husband, Andrew, who has believed in me, cheered me on and developed my
    website
  </li>
  <li>
    Our son Sean who has been the wind beneath my wings — encouraging
    me to create this business and helping me learn about the world of social
    media and patronage
  </li>
  <li>
    Our son Sam for being so positive and loving puzzles
  </li>
  <li>
    My late step-grandfather, Les Harris, for inspiring me to create my own
    artistic puzzles through his amazing puzzles
  </li>
</ul>
<h3 class="color-background-box">
  <i>
    *A <b>dissectologist</b> is a person who enjoys jigsaw puzzle assembly!
  </i>
</h3>
<br />
<br />
<br />
