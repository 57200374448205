import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css']
  })
export class NavBarComponent {
  @ViewChild('topNav') topnav;
  @ViewChild('navbarMenuItems') navbarMenuItems;

  constructor(
      public auth: AuthService,
      public el: ElementRef
  ) {}

  hamburgerClicked() {
    if (this.topnav) {
      if (this.topnav.nativeElement.className ==='topnav') {
        this.topnav.nativeElement.className += " responsive";
      } else {
        this.topnav.nativeElement.className = "topnav";
      }
    }
    // if (this.navbarMenuItems) {
    //   if (this.navbarMenuItems.nativeElement.style.display === 'block') {
    //     this.navbarMenuItems.style.display = 'none';
    //   } else {
    //     this.navbarMenuItems.nativeElement.style.display = 'block';
    //   }
    // }
  }

  //Make sure the responsive class is removed from the menuitem to cause the
  //responsive menu to disappear
  menuItemClicked() {
    if (this.topnav) {
      if (this.topnav.nativeElement.className === "topnav responsive") {
        this.topnav.nativeElement.className = "topnav";
      }
    }
  }
}
