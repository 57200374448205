<br />
<h2>Welcome to Pentinga Productions!</h2>
<h3 class="emphasize-text">
  <b><i>The puzzling fun begins here!</i></b>
</h3>
<hr />
<div style="margin-right: 80px">
  <img class="small_pic" src="../../../assets/images/puzzle piece.jpg" />
</div>
<p>
  Making custom-made wooden puzzles to delight all audiences, adults, children
  and retirees, is our thing!
</p>
<p>
  <b>
    Our puzzles are perfect for gifts, celebrations of all kinds, and keepsakes!
  </b>
</p>
<p>
  <b>Our puzzles are unique, durable and fun:</b>
</p>
<ul>
  <li>Each puzzle piece is individually, free-hand cut</li>
  <li>No two pieces or puzzles are ever the same</li>
  <li>Each puzzle is made from ¼ inch wood and will last for years!</li>
  <li>You can assemble them by yourself or with your friends / family</li>
</ul>
<p class="emphasize-text"><b>Each puzzle is a work of art!</b></p>
<p class="emphasize-text">
  <b
    >Each puzzle is an experience!
    <p class="emphasize-text"></p
  ></b>
</p>

<hr />
<p>You can decide if you want:</p>
<ul>
  <li>a puzzle made from your own photo or a professional photo</li>
  <li>a more challenging or less challenging puzzle</li>
</ul>
<p>
  Check out our
  <a [routerLink]="['/order']" routerLinkActive="active">Order Page</a>
  for details
</p>
<hr />

<p class="color-background-box">
  <b
    >We can ship puzzles to you or to someone you wish to "gift" a puzzle to!</b
  >
</p>
<hr />
<p></p>
<p>
  Please see our
  <a [routerLink]="['/gallery']" routerLinkActive="active"
    >Gallery of Puzzles</a
  >
  to ignite your appetite for creative puzzles!
</p>
<hr />
<div style="margin-bottom: 100px">
  <p style="margin-right: 80px">
    <img
      class="smaller_pic"
      src="../../../assets/images/Happy Smile (Small).jpg"
    />
    We hope you are not “puzzled” by our business… if you are, please email
    <a href="mailto:pentingaproductions@gmail.com"
      >pentingaproductions@gmail.com</a
    >

    . We're happy to answer any questions.
  </p>
</div>

<p class="color-background-box">
  <i>
    <b>Commission a puzzle today!</b>
  </i>
</p>
<br />
<br />
<br />
