<br />
<h2>Gallery</h2>
<p>
  Please enjoy photos of puzzles that have already been created or are in
  process.
</p>

<p class="emphasize-text">
  <b
    >We've made puzzles for retirees, newly engaged couples, families, children,
    adults, friends…</b
  >
</p>
<br />

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/Puppy.jpg"
    >
      <img
        src="../../../assets/images/gallery/Puppy (Small).jpg"
        alt="Puppy"
      />
    </a>
    <div class="desc">Puppy</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/Another happy engagement photo puzzle.jpg"
    >
      <img
        src="../../../assets/images/gallery/Another happy engagement photo puzzle (Small).jpg"
        alt="Happy Engagement!"
      />
    </a>
    <div class="desc">Happy Engagement!</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/Killdeer Puzzle ALMOST Complete.jpg"
    >
      <img
        src="../../../assets/images/gallery/Killdeer Puzzle ALMOST Complete (Small).jpg"
        alt="Killdeer Puzzle ALMOST Complete"
      />
    </a>
    <div class="desc">Killdeer Puzzle ALMOST Complete</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Bumble Bee.jpg">
      <img
        src="../../../assets/images/gallery/Bumble Bee (Small).jpg"
        alt="Bumble Bees"
      />
    </a>
    <div class="desc">Bumble Bees</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Daisies.jpg">
      <img
        src="../../../assets/images/gallery/Daisies.jpg"
        alt="Daisies"
      />
    </a>
    <div class="desc">Daisies in Metis sur Mer, Quebec</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/Polar Bear Puzzle in Process.jpg"
    >
      <img
        src="../../../assets/images/gallery/Polar Bear Puzzle in Process (Small).jpg"
        alt="Polar Bear Puzzle in Process"
      />
    </a>
    <div class="desc">Polar Bear Puzzle in Process</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/Prothonotary warbler puzzle in process on saw - LOTS of sawdust.jpg"
    >
      <img
        src="../../../assets/images/gallery/Prothonotary warbler puzzle in process on saw - LOTS of sawdust (Small).jpg"
        alt="Puzzle in process on saw - LOTS of sawdust"
      />
    </a>
    <div class="desc">
      Puzzle in process on saw — LOTS of sawdust
    </div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a
      target="_blank"
      href="../../../assets/images/gallery/A clients puzzle of an engagement photo.jpg"
    >
      <img
        src="../../../assets/images/gallery/A clients puzzle of an engagement photo (Small).jpg"
        alt="Another Happy Engagement!"
      />
    </a>
    <div class="desc">Another Happy Engagement!</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/A puzzler puzzling.jpg">
      <img
        src="../../../assets/images/gallery/A puzzler puzzling (Small).jpg"
        alt="A puzzler puzzling"
      />
    </a>
    <div class="desc">A puzzler puzzling</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Puzzlers.jpg">
      <img
        src="../../../assets/images/gallery/Puzzlers.jpg"
        alt="Puzzlers puzzling"
      />
    </a>
    <div class="desc">Puzzlers puzzling</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Dutch Christmas Scene.jpg">
      <img
        src="../../../assets/images/gallery/Dutch Christmas Scene.jpg"
        alt="Dutch Christmas Scene"
      />
    </a>
    <div class="desc">Dutch Christmas Scene</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Puzzling Animation 1.gif">
      <img
        src="../../../assets/images/gallery/Puzzling Animation 1.gif"
        alt="Puzzling Action!"
      />
    </a>
    <div class="desc">Puzzling Action!</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Dog 1.jpg">
      <img
        src="../../../assets/images/gallery/Dog 1.jpg"
        alt="Who's a good dog?"
      />
    </a>
    <div class="desc">Who's a good dog?</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Family Winter Outdoor Fun.jpg">
      <img
        src="../../../assets/images/gallery/Family Winter Outdoor Fun (Small).jpg"
        alt="Family Winter Outdoor Fun"
      />
    </a>
    <div class="desc">Family Winter Outdoor Fun</div>
  </div>
</div>

<div class="responsive">
  <div class="gallery-pic">
    <a target="_blank" href="../../../assets/images/gallery/Family Forest Fun.jpg">
      <img
        src="../../../assets/images/gallery/Family Forest Fun (Small).jpg"
        alt="Family Forest Fun"
      />
    </a>
    <div class="desc">Family Forest Fun</div>
  </div>
</div>

<div class="clearfix"></div>
