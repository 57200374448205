import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app',
  template: `
    <nav-bar></nav-bar>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  title = "Pentinga Productions";

  constructor(
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.checkAuthenticationStatus();
  }
}
