import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { IUser } from "../../models/user.model";

//const baseUrl = 'http://localhost:8001/api';

@Injectable()
export class AuthService {
  currentUser: IUser;
  options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  // login(username: string, password: string): Observable<IUser> {
  //   const loginInfo = {
  //     username: username,
  //     password: password,
  //   };
  //   return this.http.get<IUser>(`${baseUrl}/login, ${loginInfo}`);
  // }

  loginUser(userName: string, password: string) {
    const loginInfo = {
      username: userName,
      password: password,
    };

    return this.http
      .post('/api/login', loginInfo, this.options)
      .pipe(
        tap((data) => {
          this.currentUser = <IUser>data['user'];
        })
      )
      .pipe(
        catchError(() => {
          return of(false);
        })
      );
  }

  isAuthenticated() {
    return !!this.currentUser;
  }

  checkAuthenticationStatus() {
    // this.http
    //   .get('/api/currentIdentity')
    //   .pipe(
    //     tap((data) => {
    //       if (data instanceof Object) {
    //         this.currentUser = <IUser>data;
    //       }
    //     })
    //   )
    //   .subscribe();
  }

  updateCurrentUser(firstName: string, lastName: string) {
    this.currentUser.firstName = firstName;
    this.currentUser.lastName = lastName;

    return this.http.put(
      `/api/users/${this.currentUser.id}`,
      this.currentUser,
      this.options
    );
  }

  logout() {
    this.currentUser = undefined;
    return this.http.post('/api/logout', {}, this.options);
  }
}
