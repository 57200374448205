<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>

<div class="bg-img">
  <div class="container">
    <div class="topnav" #topNav>
      <div #navbarMenuItems>
        <a
          [routerLink]="['/home']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          title="Home"
          ><i class="fa fa-fw fa-home"></i>Home
        </a>
        <a
          [routerLink]="['/about']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          >About
        </a>
        <a
          [routerLink]="['/order']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          >Order
        </a>
        <a
          [routerLink]="['/patronage']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          >Patronage
        </a>
        <a
          [routerLink]="['/gallery']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          >Gallery
        </a>
        <a
          [routerLink]="['/testimonials']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          >Testimonials
        </a>
        <a
          [routerLink]="['/contact']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="menuItemClicked()"
          ><i class="fa fa-fw fa-envelope"></i>Contact
        </a>
        <!-- <a class="navbar-right" *ngIf="!auth.isAuthenticated()" [routerLink]="['user/login']" routerLinkActive="active" (click)="menuItemClicked()"><i class="fa fa-fw fa-user"></i>Login</a>
        <a class="navbar-right" *ngIf="auth.isAuthenticated()" [routerLink]="['user/profile']" routerLinkActive="active" (click)="menuItemClicked()"><i class="fa fa-fw fa-user"></i>{{auth.currentUser.firstName}}</a> -->
      </div>
      <a class="icon" (click)="hamburgerClicked()"
        ><i class="fa fa-bars"></i
      ></a>
    </div>
  </div>
</div>
