import { Routes } from '@angular/router'
import { AboutComponent } from '../components/about/about.component';
import { HomeComponent } from '../components/home/home.component';
import { OrderComponent } from '../components/order/order.component';
import { PatronageComponent } from '../components/patronage/patronage.component';
import { GalleryComponent } from '../components/gallery/gallery.component';
import { TestimonialsComponent } from '../components/testimonials/testimonials.component';
import { ContactComponent } from '../components/contact/contact.component';
import { Error404Component } from '../components/404/404.component';

export const appRoutes:Routes = [
  // { path: 'events/new', component: CreateEventComponent, canDeactivate: ['canDeactivateCreateEvent']},
  // { path: 'events', component: EventsListComponent, resolve: {events: EventListResolver} },
  // { path: 'events/:id', component: EventDetailsComponent, resolve: {event:EventResolver} },
  // { path: 'events/session/new', component: CreateSessionComponent },
  { path: 'home', component: HomeComponent},
  { path: 'about', component: AboutComponent},
  { path: 'order', component: OrderComponent},
  { path: 'patronage', component: PatronageComponent},
  { path: 'gallery', component: GalleryComponent},
  { path: 'testimonials', component: TestimonialsComponent},
  { path: '404', component: Error404Component},
  { path: 'contact', component: ContactComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'user',
    loadChildren: () => import('../modules/user.module').then(m => m.UserModule)
  }
]
