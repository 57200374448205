<br />
<p>
  <b>
    <h2>To Order a Custom-Made Wooden Puzzle</h2>
  </b>
</p>

<br />
<div class="color-border-box">
  <h3 class="emphasize-text">Puzzle Photo Options:</h3>
  <div style="margin-right: 60px">
    <img class="smaller_pic" src="../../../assets/images/Puzzle Piece Order Page1 (Small).jpg" />
    <img class="smaller_pic" src="../../../assets/images/Puzzle Plece Order Page2 (Small).jpg" />
  </div>
  <ol>
    <li>Your own beloved photo</li>
    <p>
      <b>or</b>
      <li>a professional photo by Ruth Pentinga</li>
    </p>
  </ol>

  <h3 class="emphasize-text">Three Puzzle PIECE size options:</h3>

  <ol>
    <li>
      LARGE Pieces (an easier puzzle for children or those with less puzzling
      experience)
    </li>
    <li>MEDIUM Pieces (a little more challenging)</li>
    <li>SMALL Pieces (more complex + challenging)</li>
  </ol>
  <h3 class="emphasize-text">Six Puzzle DIMENSION options:</h3>
  <ul>
    <li>Please see the order form for details</li>
  </ul>
  <h3 class="emphasize-text">
    Puzzles can be delivered in two different formats:
  </h3>
  <ol>
    <li>
      Assembled means the puzzle needs to be dissembled before assembling, and
      you can "see" the whole puzzle and photo.
    </li>
    <li>
      Unassembled adds more challenge, especially if you choose do it without
      the smaller photo as a guide! You choose!
    </li>
  </ol>
  <br />
</div>
<br />
<p>
  <b
    >Just click the Order Button for more information and to place your puzzle
    order</b
  >
</p>
<div class="order-button">
  <a
    class="button"
    href="https://docs.google.com/forms/d/e/1FAIpQLSd6Jb6o3bNlm1ufteLkQzXmXgtdzH90vMAolZMQnqGSDn90mg/viewform"
    target="_blank"
    >Order</a
  >
</div>
<br />
<br />
<br />
<br />
