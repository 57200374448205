import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { NavBarComponent } from '../components/nav-bar/navbar.component';
import { HomeComponent } from '../components/home/home.component';
import { AboutComponent } from '../components/about/about.component';
import { OrderComponent } from '../components/order/order.component';
import { GalleryComponent } from '../components/gallery/gallery.component';
import { TestimonialsComponent } from '../components/testimonials/testimonials.component';
import { ContactComponent } from '../components/contact/contact.component';
import { Error404Component } from '../components/404/404.component';

import { appRoutes } from '../routes/routes'
import { AppComponent } from '../components/app/app.component';
import { JQ_TOKEN } from '../services/jQuery/jQuery.service';
import { TOASTR_TOKEN, Toastr } from '../services/toastr/toastr.service';
import { CollapsibleWellComponent } from '../components/collapsible-well/collapsible-well.component';
import { ModalComponent } from '../components/modal/modal.component';
import { ModalTriggerDirective } from '../directives/modal-trigger.directive';
import { AuthService } from '../services/auth/auth.service';

const toastr:Toastr = window['toastr'];
const jQuery = window['$'];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    HttpClientModule
  ],
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    AboutComponent,
    OrderComponent,
    GalleryComponent,
    TestimonialsComponent,
    ContactComponent,
    Error404Component,
    CollapsibleWellComponent,
    ModalComponent,
    ModalTriggerDirective,
  ],
  providers: [
    { provide: TOASTR_TOKEN, useValue: toastr },
    { provide: JQ_TOKEN, useValue: jQuery },
    AuthService,
    // { provide: 'canDeactivateCreateEvent', useValue: checkDirtyState }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function checkDirtyState(component: CreateEventComponent) {
//   if (component.isDirty)
//     return window.confirm('You have not saved this event, do you really want to cancel?')
//   return true
// }
